export const isObject = (
  val: unknown,
): val is Record<string | symbol, unknown> =>
  val !== null && typeof val === 'object' && !Array.isArray(val);

export function isObjectEmpty(
  val: unknown,
): val is Record<string | symbol, unknown> {
  return isObject(val) && Object.keys(val).length === 0;
}

export const objectToString = Object.prototype.toString;
export const toTypeString = (value: unknown): string =>
  objectToString.call(value);

// eslint-disable-next-line prefer-destructuring
export const isArray = Array.isArray;
export const isMap = (val: unknown): val is Map<any, any> =>
  toTypeString(val) === '[object Map]';
export const isSet = (val: unknown): val is Set<any> =>
  toTypeString(val) === '[object Set]';

export const isDate = (val: unknown): val is Date => val instanceof Date;
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (val: unknown): val is Function =>
  typeof val === 'function';
export const isString = (val: unknown): val is string =>
  typeof val === 'string';
export const isNumber = (val: unknown): val is number =>
  typeof val === 'number';
export const isSymbol = (val: unknown): val is symbol =>
  typeof val === 'symbol';

export const isPromise = <T = any>(val: unknown): val is Promise<T> => {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch);
};

export const toRawType = (value: unknown): string => {
  // extract "RawType" from strings like "[object RawType]"
  return toTypeString(value).slice(8, -1);
};

export const isPlainObject = (val: unknown): val is Record<string, unknown> =>
  toTypeString(val) === '[object Object]';

export const isIntegerKey = (key: unknown): boolean =>
  isString(key) &&
  key !== 'NaN' &&
  key[0] !== '-' &&
  `${parseInt(key, 10)}` === key;
