import type { FC, Component } from 'react';
import type {
  PackageJson as PackageJsonBase,
  TsConfigJson as TsConfigJsonBase,
} from 'type-fest';

export { expectNever, expectType } from 'ts-expect';
export type { TypeEqual, TypeOf } from 'ts-expect';
export type {
  Except,
  Simplify,
  Merge,
  SetRequired,
  SetOptional,
  AsyncReturnType,
  PartialDeep,
} from 'type-fest';
// https://github.com/millsp/ts-toolbelt
export * as TsToolbelt from 'ts-toolbelt';
export { default as isEmpty } from 'lodash/isEmpty.js';

/**
 * Turns `Promise<string>` into `string`
 */
export type Unpromisify<T> = T extends Promise<infer R> ? R : never;

/**
 * Turns `() => Promise<string>` into `string`
 */
export type PromiseResult<T> = T extends (...args: unknown[]) => unknown
  ? Unpromisify<ReturnType<T>>
  : never;

/**
 * Opposite of `keyof`
 * @example
 * type Foo = { a: string; b: number; c: boolean };
 * type Bar = ValueOf<Foo>; // string | number | boolean
 */
export type ValueOf<T> = T[keyof T];

export type GetValueOrAsyncReturnValue<X> = X extends (
  ...args: unknown[]
) => unknown
  ? Awaited<X>
  : X;

/**
 * @template V would get `string` if passed in either `string` or `() => Promise<string>`
 */
export type ValueOrGetValue<V> = V extends (
  ...args: unknown[]
) => Promise<infer X>
  ? X
  : V;

/**
 * Typesafe `Object.keys()` that returns better than `string[]`
 */
export function keys<O extends Record<string, unknown>>(
  obj: O,
): Array<keyof O> {
  return Object.keys(obj) as Array<keyof O>;
}

/**
 * Typesafe `Object.entries()` that retains the keys actual string value instead of just `string`
 */
export function entries<O extends Record<string, unknown>>(
  obj: O,
): Array<[keyof O, O[keyof O]]> {
  return Object.entries(obj) as Array<[keyof O, O[keyof O]]>;
}

/**
 * Typesafe way to check if a key is in an object
 */
export function isObjKey<T extends Record<string | number | symbol, unknown>>(
  key: string | number | symbol,
  obj: T,
): key is keyof T {
  return key in obj;
}

/**
 * Check whether a value is defined (non-nullable), meaning it is neither `null`
 *  or `undefined`. This can be useful as a type-guard, as for example:
 * `[1, null].filter(Boolean)` does not always type-guard correctly.
 * @example
 * ```
 * import {isDefined} from 'ts-extras';
 * [1, null, 2, undefined].filter(isDefined); // => [1, 2]
 *
```
*/
export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Get Props from a React Component
 * @example
 * type Props = PropsFrom<typeof Button>;
 */
export type PropsFrom<TheComponent> = TheComponent extends FC<infer Props>
  ? Props
  : TheComponent extends Component<infer Props>
  ? Props
  : never;

export interface PackageJson extends PackageJsonBase, Record<string, unknown> {
  scripts?: {
    [scriptName: string]: string;
  };
  exports?: {
    [key: string]: PackageJsonBase.Exports;
  };
}

export interface TsConfigJson
  extends TsConfigJsonBase,
    Record<string, unknown> {}

/**
 * Expands object types recursively
 * @link https://stackoverflow.com/a/57683652
 * @deprecated Use `Simplify` instead
 */
export type ExpandRecursively<T> = T extends Record<string, unknown>
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T;

/**
 * Workaround to allow typed info to work with Object.entries
 */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
