import { format, parseISO, compareAsc, compareDesc, subDays } from 'date-fns';

/**
 * ISO date string (`2021-10-15T15:42:43.083Z`)
 */
export type DateIsoString =
  `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;

export const now = (): string => new Date().toISOString();

/** Makes 'MM/dd/yyyy' string */
export function formatDate(date: Date): string {
  return format(date, 'MM/dd/yyyy');
}

/** Get the ISO date string (`2021-10-15T15:42:43.083Z`) for right now */
export const nowInIsoDate = (): string => new Date().toISOString();

export const yesterdayInIsoDate = (): string =>
  subDays(new Date(), 1).toISOString();

export function sortDateAsc(a: { date: string }, b: { date: string }): number {
  return compareAsc(parseISO(a.date), parseISO(b.date));
}

export function sortDateDesc(a: { date: string }, b: { date: string }): number {
  return compareDesc(parseISO(a.date), parseISO(b.date));
}

export const minutesFromNowInIsoDate = (minutes: number): string => {
  return new Date(Date.now() + minutes * 60 * 1000).toISOString();
};
