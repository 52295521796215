import { isArray, isDate, isObject } from './is-types';

// Thanks! https://github.com/vuejs/vue-next/blob/master/packages/shared/src/looseEqual.ts

function looseCompareArrays(a: unknown[], b: unknown[]): boolean {
  if (a.length !== b.length) return false;
  let equal = true;
  for (let i = 0; equal && i < a.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    equal = looseEqual(a[i], b[i]);
  }
  return equal;
}

export function looseEqual(a: unknown, b: unknown): boolean {
  if (a === b) return true;

  if (isDate(a) && isDate(b)) {
    return a.getTime() === b.getTime();
  }

  if (isArray(a) && isArray(b)) {
    return looseCompareArrays(a, b);
  }

  if (isObject(a) && isObject(b)) {
    const aKeysCount = Object.keys(a).length;
    const bKeysCount = Object.keys(b).length;
    if (aKeysCount !== bKeysCount) {
      return false;
    }
    // eslint-disable-next-line guard-for-in
    for (const key in a) {
      // eslint-disable-next-line no-prototype-builtins
      const aHasKey = a.hasOwnProperty(key);
      // eslint-disable-next-line no-prototype-builtins
      const bHasKey = b.hasOwnProperty(key);
      if (
        (aHasKey && !bHasKey) ||
        (!aHasKey && bHasKey) ||
        !looseEqual(a[key], b[key])
      ) {
        return false;
      }
    }
  }
  return String(a) === String(b);
}

export function looseIndexOf(arr: unknown[], val: unknown): number {
  return arr.findIndex((item) => looseEqual(item, val));
}
