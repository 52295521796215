import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';
import { slugify } from './urls';

export const makeUuid = () => uuid();

/**
 * Short ID that is also slugified
 */
export const makeShortId = () =>
  slugify({
    string: nanoid(10),
    lowerCase: false,
  });
