import { isUrl } from './strings';

class FetcherError extends Error {}

export async function fetcher<T = Record<string, unknown>>({
  url,
  query,
  body,
  headers: customHeaders = {},
  signal,
}: {
  url: string | URL;
  query?: Record<string, string | boolean | number>;
  body?: Record<string, any>;
  headers?: RequestInit['headers'];
  signal?: AbortSignal;
}): Promise<T> {
  if (typeof url === 'string' && !isUrl(url)) {
    throw new FetcherError(`Cannot fetch from an invalid url: "${url}"`);
  }
  if (typeof url !== 'string' && !(url instanceof URL)) {
    throw new FetcherError(`Url must be a string or URL instance: "${url}"`);
  }
  const method = body ? 'POST' : 'GET';
  let fetchUrl = '';
  try {
    const u = url instanceof URL ? url : new URL(url);
    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (typeof value !== 'undefined' || value !== null) {
          u.searchParams.set(key, `${value}`);
        }
      });
    }
    fetchUrl = u.toString();
  } catch (error) {
    throw new FetcherError(
      `Could not start fetcher because was not able to add query params to URL successfully. Url: ${url}, Query params: ${JSON.stringify(
        query ?? {},
      )}. ${error.message}`,
    );
  }

  try {
    const headers: RequestInit['headers'] = {
      Accept: 'application/json',
    };
    if (body) headers['Content-Type'] = 'application/json';

    const res = await fetch(fetchUrl, {
      method,
      headers: { ...headers, ...customHeaders },
      body: body ? JSON.stringify(body) : undefined,
      signal,
    });

    if (!res.ok) {
      const resp = await res.json();
      if (resp?.error?.message) {
        throw new Error(resp.error.message);
      } else if (resp?.message) {
        throw new Error(resp.message);
      } else {
        throw new Error(`${res.statusText} ${res.status}`);
      }
    }

    if (!res.headers.get('Content-Type').includes('json')) {
      const text = await res.text();
      throw new Error(
        `Expected JSON response but received text instead: ${text}`,
      );
    }

    const data: T = await res.json();
    return data;
  } catch (error) {
    if (error.message) {
      throw new FetcherError(error.message);
    }

    const msg = `fetcher() failed http ${method} call to ${fetchUrl}`;
    throw new FetcherError(msg);
  }
}
