export { default as debounce } from 'lodash/debounce.js';
export { default as throttle } from 'lodash/throttle.js';

// https://www.npmjs.com/package/memoizee
export { default as memoize } from 'memoizee';
export { default as memoizeeProfile } from 'memoizee/profile.js';
export { flow } from 'fp-ts/lib/function.js';

/**
 * Create a fake time delay in an async function useful for testing
 * @example
 * await delay(1000);
 */
export const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));
