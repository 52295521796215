/**
 * Get Random Integer
 */
export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Timer results in ms
 */
export const timer = (): (() => number) => {
  const startTime = new Date().getTime();
  return () => new Date().getTime() - startTime;
};
/**
 * The above timer, but in seconds instead of ms
 */
export const timerInSeconds = (): (() => number) => {
  const timerEnd = timer();
  return () => timerEnd() / 1000;
};
