/**
 * Creates a Last In, First Out (LIFO) data structure list
 */
export class Stack<Item> {
  items: Item[];
  constructor(initialItems?: Item[]) {
    this.items = initialItems || [];
  }

  /** Add item to top of list */
  add(item: Item): void {
    this.items.unshift(item);
  }

  /** Remove item from top of list */
  next(): Item {
    return this.items.shift();
  }

  /** Look at item from top of list w/o removing */
  peekAtNext(): Item {
    return this.items[0];
  }

  clear(): void {
    this.items = [];
  }

  get length(): number {
    return this.items.length;
  }

  isEmpty(): boolean {
    return this.items.length === 0;
  }
}
