import QuickLru from 'quick-lru';

/**
 * A subclass of `Map` - Useful when you need to cache something and limit memory usage.
 * https://github.com/sindresorhus/quick-lru/blob/v5.1.1/readme.md
 * @example
 * ```ts
 * const lru = new LruCache({ maxSize: 1000 });
 * lru.set('a', 'b');
 * ```
 */
export const LruCache = QuickLru;
export * from './queue';
export * from './stack';
