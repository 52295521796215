import camelCase from 'lodash/camelCase.js';
import kebabCase from 'just-kebab-case'; // Why not lodash/kebabCase? https://github.com/lodash/lodash/issues/2843#issuecomment-262255270
import upperFirst from 'lodash/upperFirst.js';
import { isNode } from './js-env';
import { isEmpty } from './type-tools';

export { default as snakeCase } from 'lodash/snakeCase.js';
export { default as capitalize } from 'lodash/capitalize.js';
export { camelCase, kebabCase, kebabCase as paramCase };

/**
 * Lower and split on dots, that's it. Do NOT  split standalone strings on
 * numbers, caps, etc. Note that we split/join specifically to process each
 * segment of a token name, ie if `color.primary`, we process 'color' and 'primary'
 * individually
 */
export const cssKebabCase = (str: string) =>
  str.split('.').map(kebabCase).join('-');
export const cssVarName = (name: string) => `--${cssKebabCase(name)}`;

/**
 * This function takes a string and escapes any regex related characters
 * Intended to be used to "cleanse" user input of regex that causes failures when using string operators
 */
export function escapeRegEx(string: string): string {
  return string.replace(/[*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Basic "fuzzy" search of one string within another.
 * Both the haystack and needle are escaped for regex characters, then converted to lower case
 * If the needle appears in the haystack, will return true, otherwise returns false
 * @param {string} haystack - string to seach within
 * @param {string} needle - string being searched for
 * @returns {boolean}
 */
export function containsString(haystack: string, needle: string): boolean {
  const needleNormalized = escapeRegEx(needle).toLowerCase();
  const haystackNormalized = escapeRegEx(haystack).toLowerCase();
  return haystackNormalized.search(needleNormalized) !== -1;
}

export function capitalizeFirstLetter(string: string): string {
  return upperFirst(string);
}

export function isFirstLetterCapital(str: string): boolean {
  if (!str || str.length === 0) return false;
  return str[0] === str[0].toUpperCase();
}

/**
 * Transform into a string of capitalized words without separators. How React Component names are transformed.
 * aka PascalCase
 */
export function upperCamelCase(str: string): string {
  return capitalizeFirstLetter(camelCase(str));
}
export const pascalCase = upperCamelCase;

export function base64ToString(b64: string): string {
  return isNode ? Buffer.from(b64, 'base64').toString() : atob(b64);
}

export function stringToBase64(string: string): string {
  return isNode ? Buffer.from(string).toString('base64') : btoa(string);
}

/**
 * check if a value is a valid Base64 string
 */
export function isBase64(src: unknown): src is string {
  if (typeof src !== 'string') return false;
  const s = src.replace(/\s+/g, '').replace(/={0,2}$/, '');
  return !/[^\s0-9a-zA-Z+/]/.test(s) || !/[^\s0-9a-zA-Z\-_]/.test(s);
}

export function stringHasSpaces(string: string): boolean {
  return string.indexOf(' ') !== -1;
}

/**
 * Removes the NPM "@scope" in pkg names
 * `@scope/name` => `name`
 * `name` => `name`
 */
export function removeNpmScope(pkgName: string): string {
  if (!pkgName.startsWith('@') && !pkgName.includes('/')) {
    return pkgName;
  }
  const [, name] = pkgName.split('/');
  if (!name) return pkgName;
  return name;
}

export function isUrl(url: unknown): url is string {
  if (typeof url !== 'string') return false;
  try {
    const _u = new URL(url);
    return true;
  } catch {
    return false;
  }
}
// RFC 5322 spec, https://emailregex.com/
const emailMatcher =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * Check that email is < 320 chars (according to spec), then if passes regex.
 * @link https://www.rfc-editor.org/errata/eid1690#:~:text=It%20should%20say%3A-,In%20addition%20to%20restrictions%20on%20syntax%2C%20there%20is%20a%20length,total%20length%20of%20320%20characters.
 */
export const isEmail = (email: unknown): email is string =>
  typeof email === 'string' && email.length <= 320 && emailMatcher.test(email);

/**
 * Returns "knapsack.cloud" from "bloom@knapsack.cloud", if valid email
 */
export const getDomainFromEmail = (email: string): string | null =>
  isEmail(email) ? email.substring(email.indexOf('@') + 1) : null;

/**
 * Basic auth headers are username:password in base64, decode and compare
 */
export const decodeBasicAuth = (
  auth: string,
): { username: string; password: string } => {
  const [username, password] = base64ToString(auth).split(':');
  return { username, password };
};

/**
 * Parse a string that may contain a unit, like "10px" or "10%"
 */
export function parseNumberWithUnit(value: string | number): {
  value: number;
  unit?: string;
} {
  const stringValue = String(value).trim();
  const unitRegex = /([a-zA-Z%])+$/;
  const match = stringValue.match(unitRegex);

  let numberValue: number;
  let unit: string;

  if (match) {
    const unitIndex = match.index;
    numberValue = parseFloat(stringValue.slice(0, unitIndex));
    [unit] = match;
  } else {
    numberValue = parseFloat(stringValue);
    unit = '';
  }
  if (Number.isNaN(numberValue)) {
    throw new Error(`Invalid value, not a number: ${value}`);
  }

  return { value: numberValue, unit };
}

/**
 * Check if a strings first character is a letter
 */
export function startsWithLetter(str: string): boolean {
  return /^[a-zA-Z]/.test(str);
}

export function removeWrappingDoubleQuotes(str: string): string {
  if (!str || typeof str !== 'string') return str;
  return str.replace(/^"(.*)"$/, '$1');
}

/**
 * Transforms a name string containing key-value pairs into a shortened version.
 *
 * Example:
 * Input: "mode=dark, status=on, debug=false"
 * Output: "dark, status"
 */
export const getShortName = (name: string) => {
  if (!name || isEmpty(name)) return;

  if (!name.includes('=')) {
    return name;
  }

  const nameParts = name.split(',');
  const shortNameParts: string[] = [];

  nameParts.forEach((part) => {
    const [key, value] = part.split('=');

    if (!value || ['false', 'off', 'no'].includes(value)) {
      return;
    }
    if (['true', 'on', 'yes'].includes(value)) {
      return shortNameParts.push(key);
    }
    return shortNameParts.push(value);
  });
  return shortNameParts.join(', ');
};
