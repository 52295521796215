export { default as uniqBy } from 'lodash/uniqBy.js';

/**
 * Make an array unique by removing duplicate entries.
 * @param ar - Array to make unique
 * @returns A unique array
 */
export function uniqueArray<T>(ar: T[]): T[] {
  const j = {};
  ar.forEach((v) => {
    j[`${v}::${typeof v}`] = v;
  });
  return Object.keys(j).map((v) => j[v]);
}

/**
 * Is Some Of This Array In That Array?
 * Are any of the items in arrayA in arrayB?
 */
export function hasItemsInItems<T extends string | number | boolean>(
  arrayA: T[],
  arrayB: T[],
): boolean {
  return arrayA.some((a) => arrayB.includes(a));
}

/**
 * Used to ensure that the `id` prop in each object in an array is unique
 */
export function assertUniqueIdInArray<T, Key extends string & keyof T>({
  items,
  key,
  errorMsgPrefix,
}: {
  items: T[] | readonly T[];
  /** Can be `id` or `value` */
  key: Key;
  errorMsgPrefix?: string;
}) {
  const ids = [];
  const duplicates = [];
  items.forEach((item) => {
    if (ids.includes(item[key])) {
      duplicates.push(item);
    } else {
      ids.push(item[key]);
    }
  });
  if (duplicates.length > 0) {
    const duplicateIdList = duplicates.map((d) => `"${d[key]}"`).join(', ');
    throw new Error(
      `${
        errorMsgPrefix ||
        `Each item in the array requires unique "${key}" prop.`
      } These ids are duplicates: ${duplicateIdList}`,
    );
  }
}

export function isArrayOfStringsUnique(arr: string[]): boolean {
  const unique = new Set(arr);
  return arr.length === unique.size;
}

export function getNonUniqueStringsFromArray(arr: string[]): string[] {
  const unique = new Set<string>();
  return arr.filter((a) => {
    if (unique.has(a)) {
      return true;
    }
    unique.add(a);
    return false;
  });
}

/**
 * Converts an array of objects to a dictionary using a specified key.
 *
 * @see {@link dictionaryToArray}
 *
 * @example
 * const array = [
 *   { id: '123', name: 'foo' },
 *   { id: '456', name: 'bar' }
 * ];
 * const result = arrayToDictionary(array);
 * // Result: { '123': { name: 'foo' }, '456': { name: 'bar' } }
 *
 * const customResult = arrayToDictionary(array, 'name');
 * // Result: { 'foo': { id: '123' }, 'bar': { id: '456' } }
 */
export function arrayToDictionary<
  T extends Record<string, unknown>,
  K extends keyof T & string,
>(
  /**
   * The array of objects to convert
   */
  array: T[],
  /**
   * The property to use as the dictionary key
   */
  keyProp: K,
): Record<string, T> {
  return array.reduce((acc, item) => {
    const { [keyProp]: key } = item;
    if (acc[key as string]) {
      throw new Error(`Duplicate key: ${key}`);
    }
    acc[key as string] = item;
    return acc;
  }, {} as Record<string, T>);
}
