import { StructError } from 'superstruct';

export function getSuperstructErrorMsg(
  structError: StructError | Error | unknown,
): string {
  if (structError instanceof StructError) {
    // just doing first error b/c showing all can be kinda crazy. may want to change in future
    const [failure] = structError.failures();
    if (!failure) return '';
    const { path, message } = failure;
    const msg =
      path.length === 0 ? message : `At path: ${path.join('.')} -- ${message}`;
    return msg;
  }
  if (structError instanceof Error) {
    return structError.message;
  }
  return '';
}
