import cleanDeep from 'clean-deep';
import { isObject } from './is-types';

export { default as groupBy } from 'lodash/groupBy.js';
// @todo: look into lodash/merge vs deepMerge behavior differences and use cases (ie. immutable vs not)
// export { default as deepMerge } from 'lodash/merge.js';
export { default as deepMerge } from 'deepmerge';

/**
 * Sort object by alphabetizing keys.
 */
export function sortObject<T extends Record<string, unknown>>(theObject: T): T {
  return Object.keys(theObject)
    .sort()
    .reduce((obj, key: keyof T) => {
      obj[key] = theObject[key];
      return obj;
    }, <T>{});
}

/**
 * Remove empty objects in nested object.
 * Leverage stringify to convert empty objects to undefined, which is removed
 * during stringification.
 */
export const removeEmptyObjects = <T extends Record<string, unknown>>(
  src: T,
): Partial<T> => cleanDeep(src);

/**
 * Removes keys like `{ x: undefined }` from the object
 */
export function removeUndefinedProps<T extends Record<PropertyKey, unknown>>(
  obj: T,
): T {
  return cleanDeep(obj, {
    undefinedValues: true,
    nullValues: true,
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: true,
  }) as T;
}

/**
 * Sort object and arrays recursively by alphabetizing keys.
 */
export function sortKeysRecursive(object) {
  if (typeof object !== 'object' || !object) return object;
  if (Array.isArray(object)) {
    const newArray = [];
    object.forEach((item, index) => {
      newArray[index] = sortKeysRecursive(item);
    });
    return newArray;
  }
  const sortedKeys = Object.keys(object).sort();
  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = sortKeysRecursive(object[key]);
  });
  return sortedObject;
}

/**
 * Turn a nested object into a single-level object with dot notation keys and
 * values equal to the deepest **object** in the nested object.
 */
export function flatten(
  obj: Record<string, unknown>,
): Record<string, Record<string, unknown>> {
  if (!isObject(obj)) {
    throw new Error('Expected an object to flatten');
  }
  const flattenedObj: Record<string, Record<string, unknown>> = {};

  function dive(thisObj: Record<string, unknown>, path: string[]) {
    Object.entries(thisObj).forEach(([key, value]) => {
      const thisPath = [...path, key];
      if (isObject(value)) {
        flattenedObj[thisPath.join('.')] = value;
        dive(value, thisPath);
      }
    });
  }

  dive(obj, []);

  return flattenedObj;
}

/**
 * This function takes a dictionary of objects and returns an array of objects with the `id` merged into the object.
 * Helpful when you have a dictionary of objects and you want to add the `id` to the object (from dictionary key) then want to use array methods.
 * Example:
 * ```ts
 * const dictionary = {
 *  '123': { name: 'foo' },
 *  '456': { name: 'bar' },
 * }
 *
 * dictionaryToArray(dictionary).forEach(({ id, name }) => {
 *   // stuff
 * })
 * ```
 * @see {@link updateDictionaryWithId}
 * @see {@link arrayToDictionary}
 */
export function dictionaryToArray<T extends Record<string, any>>(
  dictionary: Record<string, T>,
): (T & { id: string })[] {
  return Object.entries(dictionary).map(([id, value]) => ({
    id,
    ...value,
  }));
}

/**
 * This function takes a dictionary of objects and returns a dictionary of objects with the `id` merged into the object.
 * Helpful when you have a dictionary of objects and you want to add the `id` to the object (from dictionary key)
 * @see {@link dictionaryToArray}
 * @example
 * ```ts
 * const dictionary = {
 * '123': { name: 'foo' },
 * '456': { name: 'bar' },
 * }
 *
 * const dictionaryWithId = updateDictionaryWithId(dictionary)
 * // Which is now:
 * {
 *   '123': { id: '123', name: 'foo' },
 *   '456': { id: '456', name: 'bar' },
 * }
 * ```
 */
export function updateDictionaryWithId<T extends Record<string, any>>(
  dict: Record<string, T>,
): Record<string, T & { id: string }> {
  return Object.entries(dict).reduce((acc, [id, value]) => {
    acc[id] = {
      id,
      ...value,
    };
    return acc;
  }, {} as Record<string, T & { id: string }>);
}
