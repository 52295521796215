/* global window self */

// Thanks! https://github.com/flexdinesh/browser-or-node/blob/master/src/index.js

export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

/* eslint-disable no-restricted-globals */
export const isWebWorker =
  typeof self === 'object' &&
  self.constructor &&
  self.constructor.name === 'DedicatedWorkerGlobalScope';
/* eslint-enable no-restricted-globals */

export const isNode =
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null;

/**
 * @see https://github.com/jsdom/jsdom/releases/tag/12.0.0
 * @see https://github.com/jsdom/jsdom/issues/1537
 */
/* eslint-disable no-undef */
export const isJsDom = () =>
  (typeof window !== 'undefined' && window.name === 'nodejs') ||
  navigator.userAgent.includes('Node.js') ||
  navigator.userAgent.includes('jsdom');
